import React, { useEffect, useState, useContext} from 'react'
import PropTypes from 'prop-types';
import styles from './MainMenuCustomer.module.css';
import MenuBackground from './MenuBackground';
import Header from "../RhyteItComponents/Header/Header"
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {ValidateUser, GetGroupRoleAccess} from '../../BusinessLogic/UserManagement';
import { useNavigate, useLocation } from "react-router-dom";
import {
    Table,
    Button,
    TableBody,
    TableRow,
    TableCell    ,
    Flex,
    Link,
    Authenticator,
    Text,
    useAuthenticator,
    Fieldset
    } from '@aws-amplify/ui-react'; 
import { signOut } from 'aws-amplify/auth';

const MainMenuCustomer  = (props) => {
 
    MainMenuCustomer.propTypes = {
    UserId: PropTypes.string,
    UserName: PropTypes.string
  };


    const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuCustomer", Title:"Menu", isCurrent:true}]

    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    let navigate = useNavigate(); 
    const {Users, setUsers} = useContext(UserContext);    
    const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);       
    const [vTitle, setvTitle] = useState();

    var errormessageStr = ""

    const [errormessage, seterrormessage] = useState(errormessageStr) 

    useEffect(() => {

    },[])  

 


    useEffect(() => {
            
      (async function() {
        try {  
          //console.log("MainMenuCustomer.useEffect[CognitoGroup].authStatus: " ,authStatus, " - .CognitoGroup: " ,CognitoGroup, " - .Users: " ,Users);

              let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteItCognitoCustomers")
              
              if (GetGroupRoleAccessResponse) {      
                setvTitle("Main Menu - " + Users)
              }
         
            } catch (err) {
              console.error('Emails.useEffect[].ERROR: ', err, err.stack); 
          }
        }
      )();   

       },[CognitoGroup])             



    useEffect(() => {

    },[vTitle])  


    async function handleSignOut() {
        try {
          await signOut();

        
          if (process.env.REACT_APP_ENV === "PRODUCTION") {
            window.location.href = "https://www.rhyteit.com";
          } else {
            window.location.href = "https://localhost:3000";
          }

        } catch (error) {
          //console.log('error signing out: ', error);
        }
      }





  return (   
  <Authenticator>
    {({ signOut, user }) => (
        <div>                        
            <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#777"   GradientStopColor4="#8880"/> 
            <Flex direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="1rem"
                    className={styles.GridContainer}>    
                    <Header Title={vTitle}  SubTitle="Build your adult content business!" BreadCrumbItems ={vBreadCrumbItems} pageSource="MainMenuCustomer"/>        
                    <Text  className={styles.errormessage}  errormessage={errormessage}></Text>    
                    
                    <Text  className={styles.TextLabel}>TurboFanBoost</Text>  
                    <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="wrap"
                          gap="3rem"
                          className={styles.CategoryRow}  
                        >                   
                        <Link className={styles.Link} href="/SMMADCampaign" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Upload content</Button>
                        </Link>                    
                        <Link className={styles.Link} href="/SMMADAGManager" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Manage Account Groups</Button>
                        </Link>                    
                        <Link className={styles.Link} href="/TurboFanBoostUser" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Manage accounts</Button>
                        </Link>    
                        <Link className={styles.Link} href="/SMMADOrganize" isExternal={false}>
                        <Button className={styles.metallicbutton}    
                                variation="primary">Organize</Button>        
                            
                        </Link>            
                        <Link className={styles.Link} href="/SMMADContentMetrics" isExternal={false}>
                        <Button className={styles.metallicbutton}    
                                variation="primary">Statistics</Button>        
                            
                        </Link>                                                              
                    </Flex>    
                    <Text  className={styles.Text}>Newsletter</Text>  
                    <Fieldset variation="outlined"
                            direction="row"
                            gap="1rem"
                            className={styles.Fieldset}>                           

                        <Link className={styles.Link} href="/NewsletterManager" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Newsletter</Button>
                        </Link>                                                                                        
                    </Fieldset>                    
                    <Text  className={styles.TextLabel}>Support</Text> 
                    <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="wrap"
                          gap="3rem"
                          className={styles.CategoryRow}  
                        >                   
                        <Link className={styles.Link} href="https://discord.gg/QymmajFQ" isExternal={true}>
                        Discord
                        </Link>   
                        <Link className={styles.Link} href="https://twitter.com/RhyteIt" isExternal={true}>
                        Twitter
                        </Link>    
                        <Link className={styles.Link} href="https://medium.com/@support_13571" isExternal={true}>
                        Medium
                        </Link>        
                        <Link className={styles.Link} href="https://www.instagram.com/rhyteit/" isExternal={true}>
                        Instagram
                        </Link>     
                        <Button className={styles.metallicbutton}
                                variation="primary"                             
                                onClick={ (e) => handleSignOut()}>Sign Out
                        </Button>                                                                
                    </Flex>     
                    
                    {process.env.REACT_APP_ENV === "TEST" && (
                        <Text  className={styles.UserStatusText}>{CognitoGroup}</Text>
                        )
                      }

            </Flex>   
        </div>       )}
    </Authenticator>     
   )
                                    }

export default MainMenuCustomer