
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  Fieldset
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../graphql/queries'
  import  { deleteContent, updateContent, createContent}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './PodcastEpisode.module.css';  
  
function PodcastEpisode({
  PodcastEpisode
}) {       

  PodcastEpisode.propTypes = {
      PodcastEpisode: PropTypes.object,
      HashTagMap: PropTypes.array,
      ImageToTextRelationship: PropTypes.bool,
      AccountSelected: PropTypes.string,
      CampaignSelected: PropTypes.string,
      onClick: PropTypes.func,
      AccountGroup: PropTypes.string,
      };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vPodcastEpisode, setvPodcastEpisode] = useState(PodcastEpisode)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
  
        

    //console.log("PodcastEpisode.useEffect[].vSelectedImage:  ",vSelectedImage) 

    useEffect(() => {

      
      
      
      },[])  

      useEffect(() => {
        //console.log("Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);
     
         if (isInView) {
           mainControls.start("visible");
         }
       }, [isInView]);      


      async function PlayEpisode(event,pFile) {
        try {

          //console.log("PodcastEpisode.PlayEpisode.pFile: " ,pFile);

          let audio = new Audio(pFile)

          audio.play()


        
            } catch (err) { 
  
            ErrorMessageStr = "PodcastEpisode.PlayEpisode.Error: " + err + " " + err.stack
  
            console.error(ErrorMessageStr); 
            
            setErrorMessage(ErrorMessageStr)         
          }               
        
      }     



  return (
        
    <div  ref={ref}  className={styles.TableRowBackground} 
          key={vPodcastEpisode.id} > 
          

                <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="1rem"
                    className = {styles.EpisodeRowFlex}>         
                    <Image
                        alt=""
                        src={vPodcastEpisode.Image}  
                        objectFit="initial"
                        objectPosition="50% 50%"
                        className={styles.ImageActive}
                        //onMouseEnter={(e) => Alert(e)}
                      /> 
                    <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="wrap"
                      gap="1rem"
                      className = {styles.FlexRightColumn}>  
                      <motion.div 
                            className = {styles.PodcastTitleDiv}
                            style={{
                                transform: isInView ? "none" : "translateX(-200px)",
                                opacity: isInView ? 1 : 0,
                                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                              }}> 
                          <motion.h1  className = {styles.PodcastTitle}>
                            {vPodcastEpisode["Title"]}
                          </motion.h1>   
                      </motion.div>   
                      <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="flex-start"
                        wrap="wrap"
                        gap="1rem"
                        className = {styles.DetailRow}>        
                          <motion.div 
                                className = {styles.PodcastDescriptionDiv}
                                style={{
                                    transform: isInView ? "none" : "translateX(-200px)",
                                    opacity: isInView ? 1 : 0,
                                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                                  }}> 
                              <Button className = {styles.Button} 
                                onClick={(e) => PlayEpisode(e, vPodcastEpisode["file"])}  
                                
                                >   
                                  Listen                  
                              </Button>    
                          </motion.div>                               
                        <motion.div 
                              className = {styles.PodcastDescriptionDiv}
                              style={{
                                  transform: isInView ? "none" : "translateX(-200px)",
                                  opacity: isInView ? 1 : 0,
                                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                                }}> 
                            <motion.h1  className = {styles.Date}>
                              Published: {vPodcastEpisode["Date"]}
                            </motion.h1>   
                        </motion.div>                         
                          <motion.div 
                                className = {styles.PodcastDescriptionDiv}
                                style={{
                                    transform: isInView ? "none" : "translateX(-200px)",
                                    opacity: isInView ? 1 : 0,
                                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                                  }}> 
                              <motion.h1  className = {styles.Duration}>
                                Duration: {vPodcastEpisode["Duration"]}
                              </motion.h1>   
                          </motion.div>                                                                                                                                                                                             
                        </Flex>             
                        <motion.div 
                              className = {styles.PodcastDescriptionDiv}
                              style={{
                                  transform: isInView ? "none" : "translateX(-200px)",
                                  opacity: isInView ? 1 : 0,
                                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                                }}> 
                            <motion.h1  className = {styles.PodcastDescription}>
                              {vPodcastEpisode["Description"]}
                            </motion.h1>   
                        </motion.div>                                                                                                                                                                         
                    </Flex>                                                                                                                          
                </Flex> 
                <div className={styles.EndLine}/>
    </div>
  )
}

export default PodcastEpisode;

