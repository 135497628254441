
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  Image,
  Button,
  SelectField,
  Flex
  } from '@aws-amplify/ui-react'; 
import NewsletterPublisherRow from './NewsletterPublisherRow';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './NewsletterPublisherTable.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  listNewsletterEditionTrackings,
  listNewsletterEditions,
  listNewsletterSubscribers,
} from "../../../graphql/queries";
import {
  createNewsletterEditionTracking,
  deleteNewsletterEditionTracking
} from "../../../graphql/mutations";


const NewsletterPublisherTable = (props) => {       

  NewsletterPublisherTable.propTypes = {
      Newsletter: PropTypes.object,
      Users: PropTypes.string,
      };

    let debugConsoleOutPut = true
  

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter)   
    const [Users, setUsers] = useState(props.Users)   
    const [vSubscribers, setvSubscribers] = useState()    
    const [ShowTable, setShowTable] = useState(false); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vUpdateKey, setvUpdateKey] = useState()   
    const [vSubscribersRefresh, setvSubscribersRefresh] = useState()  
    let [vNewSubscriber, setvNewSubscriber] = useState();
    const [vAddSubscribersVisible, setvAddSubscribersVisible] = useState(false);
    const [updatedKey, setupdatedKey] = useState();
    const [vNewsletterEditionSelected, setvNewsletterEditionSelected] = useState({"Edition":"None"});
    let [vNewsletterEditionList, setvNewsletterEditionList] = useState();
    const [vNewsletterEditionVisible, setvNewsletterEditionVisible] = useState(false);
    const [vNewsletterEditions, setvNewsletterEditions] = useState()   
    const [vNewsletterSubscribers, setvNewsletterSubscribers] = useState()   

    
    const [vContentHTML, setvContentHTML] = useState('');
    const [vTemplateJSON, setvTemplateJSON] = useState()  
    const [vContentJSON, setvContentJSON] = useState()  

    
    const [vS3Key, setvS3Key] = useState('');
    const [vEmailId, setvEmailId] = useState('');

    useEffect(() => {

    },[])  

    useEffect(() => {
      //console.log("Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);
   
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);

    useEffect(() => {

     //console.log("NewsletterPublisherTable.useEffect[vSubscribers].vSubscribers:  ",vSubscribers)
      //console.log("NewsletterPublisherTable.useEffect[].vSubscribers ",vSubscribers) 
      //console.log("vNewsletterPublisherTable.useEffect[].props.RhyteItUser:  ",props.RhyteItUser) 
      //console.log("vNewsletterPublisherTable.useEffect[].Time:  ", NewsletterPublisherRow.PostDateTime.substr(NewsletterPublisherRow.PostDateTime.indexOf("T")+1, 5))
      //console.log("vNewsletterPublisherTable.useEffect[].indexOf:  ", NewsletterPublisherRow.PostDateTime.indexOf(":00"))
      if (vSubscribers !== undefined) {
        if (vSubscribers.length > 0) {
          setShowTable(true)        
          setvUpdateKey(Date.now())
          setvSubscribersRefresh(Date.now)
          }
      }

    },[vSubscribers])  

    useEffect(() => {
      //console.log("NewsletterPublisherTable.useEffect[vNewsletter].vNewsletter:  ",vNewsletter)
  
      if (vNewsletter !== undefined) {

        const vTemplateJSONTmp = JSON.parse(vNewsletter["TemplateJSON"]);  
        setvTemplateJSON(vTemplateJSONTmp)

        GetNewsletterEditions()
        GetSubscribers()
        GetNewsletterTrackingTable()
      }
    }, [vNewsletter]);
  
    useEffect(() => {
      //console.log("NewsletterPublisherTable.useEffect[vSubscribers].vSubscribers:  ",vSubscribers)

      if (vSubscribers !== undefined) {

        setvUpdateKey(Date.now())

       }
 
     },[vSubscribers])    
     
     useEffect(() => {
      //console.log("NewsletterPublisherTable.useEffect[vNewsletterSubscribers].vNewsletterSubscribers:  ",vNewsletterSubscribers)

     },[vNewsletterSubscribers])     
     

     useEffect(() => {
      //console.log("NewsletterPublisherTable.useEffect[vHostEmail].vHostEmail:  ",vHostEmail)

      if (vNewsletterEditionSelected["Edition"] !== "None") {

          GetNewsletterTrackingTable()
          CreateMetaData()
          setvContentHTML(vNewsletterEditionSelected["ContentHTML"])
          const vContentJSONTmp = JSON.parse(vNewsletterEditionSelected["ContentJSON"]);  
          setvContentJSON(vContentJSONTmp)  

       }
 
     },[vNewsletterEditionSelected])   
     
     useEffect(() => {
        
      if (vContentJSON !== undefined) {
         
          //buildHtmlContent()
          ReplaceTemplateVariables()
        }


    }, [vContentJSON]);         
     


     async function CreateMetaData() {
        
  
      try {           
          setErrorMessage("")
          
          var vUpdateDateTime = new Date().toISOString()
      
          let vId = (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewsletterEditionSelected["Edition"]+ "-" + Users+ "-" +vUpdateDateTime).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '');
          let vS3Key = "/"+ (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewsletterEditionSelected["Edition"]+ "-" + Users).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '') +"/"+ vId+".html"          
          
          setvEmailId(vId)
          setvS3Key(vS3Key)
  
        } catch (err) { 
          RhyteItLogging(Users, "NewsletterEditionPreview", "CreateNewsletterEditionTracking",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterEditionPreview.CreateNewsletterEditionTracking.Error:  ".err, err.stack); 
      }        
    } 
  

     async function GetNewsletterEditions() {
      try {
        setErrorMessage("");
        
        //console.log("""NewsletterEditorTable.GetNewsletterEditions.Users: ", Users);
        let variables;
        if (Users !== "edwardhawke@rhyteit.com") {
          variables = {
            filter: {
              AccountGroup: {
                eq: vNewsletter["AccountGroup"],
              },
              Newsletter: {
                eq: vNewsletter["Newsletter"],
              },
            },
          };
        } else {
          variables = {};
        }
  
        const listNewsletterEditionsData2 = await client.graphql({
          query: listNewsletterEditions,
          variables: variables,
        });
  
        //console.log("""NewsletterEditorTable.GetNewsletterEditions.listNewsletterEditions.data.listNewsletterEditions.items.length : ", listNewsletterEditionsData2.data.listNewsletterEditions.items.length );
  
        if (listNewsletterEditionsData2.data.listNewsletterEditions.items.length > 0) {
          const NewsletterEditorTable1 =
            listNewsletterEditionsData2.data.listNewsletterEditions.items;
            let vSortedNewsletterEditions = SortNewsletterEditionsByName(NewsletterEditorTable1)
            setvNewsletterEditions(vSortedNewsletterEditions)
            setvNewsletterEditionList(vSortedNewsletterEditions)
        } 
  
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterEditorTable",
          "GetNewsletterEditions",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
  
    function SortNewsletterEditionsByName(pNewsletterEditionEmail) {
      pNewsletterEditionEmail.sort(GFG_sortFunction);
      return pNewsletterEditionEmail;
      //    //console.log(""JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let NewsletterEditionA = a.Edition;
      let NewsletterEditionB = b.Edition;
      return NewsletterEditionA > NewsletterEditionB ? 1 : -1;
    }
       

    async function GetNewsletterTrackingTable() {
      try {
        setErrorMessage("");

        setvSubscribers()
        
        //console.log("NewsletterPublisherTable.GetNewsletterTrackingTable.Users: ", Users);
        let variables;
        if (Users !== "edwardhawke@rhyteit.com") {
          variables = {
            filter: {
              AccountGroup: {
                eq: vNewsletterEditionSelected["AccountGroup"],
              },
              Newsletter: {
                eq: vNewsletterEditionSelected["Newsletter"],
              },
              Edition: {
                eq: vNewsletterEditionSelected["Edition"],
              },
            },
          };
        } else {
          variables = {};
        }
  
        const listNewsletterEditionTrackingsData2 = await client.graphql({
          query: listNewsletterEditionTrackings,
          variables: variables,
        });
  
        //console.log("NewsletterPublisherTable.GetNewsletterTrackingTable.listNewsletterEditionTrackings.data.listNewsletterEditionTrackings.items.length : ", listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings.items.length );
  
        if (listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings.items.length > 0) {
          const NewsletterPublisherTable1 =
            listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings.items;
            let vSortedSubscribers = SortPublishedByUpdateDateTime(NewsletterPublisherTable1)
            setvSubscribers(vSortedSubscribers)
        } 
  
        
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "GetNewsletterTrackingTable",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

    function SortPublishedByUpdateDateTime(pSubscriberEmail) {
      pSubscriberEmail.sort(UpdateDateTime_sortFunction);
      return pSubscriberEmail;
      //    //console.log(JSON.stringify(pEmails));
    }
  
    function UpdateDateTime_sortFunction(a, b) {
      let UpdateDateTimeA = a.UpdateDateTime;
      let UpdateDateTimeB = b.UpdateDateTime;
      return UpdateDateTimeA < UpdateDateTimeB ? 1 : -1;
    }

    async function GetSubscribers() {
      try {
        setErrorMessage("");
        
        //console.log("NewsletterPublisherTable.GetSubscribers.vNewsletterEditionSelected: ", vNewsletterEditionSelected);
        let variables;
        if (Users !== "edwardhawke@rhyteit.com") {
          variables = {
            filter: {
              AccountGroup: {
                eq: vNewsletter["AccountGroup"],
              },
              Newsletter: {
                eq: vNewsletter["Newsletter"],
              },
              SubscriptionStatus: {
                eq: "subscribed",
              }
            },
          };
        } else {
          variables = {};
        }
  
        const listNewsletterSubscribersData2 = await client.graphql({
          query: listNewsletterSubscribers,
          variables: variables,
        });
  
        //console.log("NewsletterPublisherTable.GetSubscribers.listNewsletterSubscribers.data.listNewsletterSubscribers.items.length : ", listNewsletterSubscribersData2.data.listNewsletterSubscribers.items.length );
  
        if (listNewsletterSubscribersData2.data.listNewsletterSubscribers.items.length > 0) {
          const NewsletterPublisherTable1 =
            listNewsletterSubscribersData2.data.listNewsletterSubscribers.items;
            setvNewsletterSubscribers(NewsletterPublisherTable1)
        } 
  
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "GetSubscribers",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }
      
    function SortSubscribersByName(pSubscriberEmail) {
      pSubscriberEmail.sort(GFG_sortFunction);
      return pSubscriberEmail;
      //    //console.log(JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let SubscriberEmailA = a.SubscriberEmail;
      let SubscriberEmailB = b.SubscriberEmail;
      return SubscriberEmailA > SubscriberEmailB ? 1 : -1;
    }
  
    async function ViewConfig(event) {
      try {
        //console.log("NewsletterPublisherTable.ViewConfig.event: ", event);
        //console.log("NewsletterPublisherTable.ViewConfig.vViewPostingConfig: ", vViewPostingConfig);
        setvAddSubscribersVisible(!vAddSubscribersVisible)
  
  
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "ViewConfig",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

    async function NewsletterEditionSelectedAction(pNewsletterEdition) {
      try {
        //    //console.log("""NewsletterManager.NewsletterSelectedAction.pAccount: ", pAccount);
        let vSelectedNewsletterGroup = vNewsletterEditionList.filter(
          (vNewsletterEditionItem) => vNewsletterEditionItem["Edition"] === pNewsletterEdition
        );
  
        //console.log("""NewsletterManager.NewsletterEditionSelectedAction.vSelectedNewsletterGroup: ",vSelectedNewsletterGroup);
        //console.log("""NewsletterManager.NewsletterSelectedAction.vSelectedNewsletter[0]: ",vSelectedNewsletter[0]);
        setvNewsletterEditionVisible(true);
        
        //setvNewsletterSelected(pAccount);
        setvNewsletterEditionSelected(vSelectedNewsletterGroup[0]);
      } catch (err) {
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "NewsletterEditionSelectedAction",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }  

    async function IterateThroughSubscribers(pDeliveryStatus) {
      try {

        //console.log("NewsletterPublisherTable.IterateThroughSubscribers.vNewsletterEditionSelected[Edition]: ", vNewsletterEditionSelected["Edition"]);


        if (vSubscribers === undefined) {

          vNewsletterSubscribers.forEach(vNewsletterSubscriber => {
    
              SaveNewsletterEditionToSubscriber(vNewsletterSubscriber["SubscriberEmail"], pDeliveryStatus)
    
            })
        } else {


          vNewsletterSubscribers.forEach(vNewsletterSubscriber => {
           
            let vDoesSubscriberExistForThisEdition = vSubscribers.filter(
              (vSubscriber) => vNewsletterSubscriber["SubscriberEmail"] === vSubscriber["RecipientEmail"] && vNewsletterSubscriber["Newsletter"] === vSubscriber["Newsletter"] && vNewsletterSubscriber["AccountGroup"] === vSubscriber["AccountGroup"] && vNewsletterEditionSelected["Edition"] === vSubscriber["Edition"]
            );
    
            if (vDoesSubscriberExistForThisEdition.length === 0 && vNewsletterSubscriber["SubscriberEmail"] !== undefined) {
    
              SaveNewsletterEditionToSubscriber(vNewsletterSubscriber["SubscriberEmail"], pDeliveryStatus)
    
            }
    
            });


        }

       

      } catch (err) {
        console.error("NewsletterSubscriberTable.PublishNewsletter.Error:  ".err, err.stack); 
        RhyteItLogging(
          Users,
          "NewsletterPublisherTable",
          "PublishNewsletter",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }  

    function convertToISO(datetimeStr) {
      // Create a new Date object from the provided string
      let date = new Date(datetimeStr.replace(' ', 'T') + 'Z'); // Replace the space with 'T' to make it compatible
      
      // Convert it to ISO string
      return date.toISOString();
    }  

    async function PublishNewsletter(event) {        
  
      try {           
      
          IterateThroughSubscribers("PUBLISH")


        } catch (err) { 
          RhyteItLogging(Users, "NewsletterSubscriberTable", "PublishNewsletter",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterSubscriberTable.PublishNewsletter.Error:  ".err, err.stack); 
      }        
    }  

    async function SendNewsletter() {        
  
      try {           
      
          IterateThroughSubscribers("SEND")


        } catch (err) { 
          RhyteItLogging(Users, "NewsletterSubscriberTable", "SendNewsletter",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterSubscriberTable.PublishNewsletter.Error:  ".err, err.stack); 
      }        
    }  

    async function SaveNewsletterEditionToSubscriber(pSubscriber, pDeliveryStatus) {
      try {

        //console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.pSubscriber:  ",pSubscriber);
        var vUpdateDateTime = new Date().toISOString()

        let vId = (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewsletterEditionSelected["Edition"]+ "-" + pSubscriber+ "-" +vUpdateDateTime).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '');
        let vS3Key = "/"+ (vNewsletter["Newsletter"] + "-" + vNewsletter["AccountGroup"] + "-" + vNewsletterEditionSelected["Edition"]+ "-" + pSubscriber).replace(/["']/g, "").replace(/\s+/g, '').replace(/[^\w]/g, '') +"/"+ vId+".html"          

  
        //console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.vId:  ",vId);
        
        let vContentHTMLFinal = vContentHTML.replace(/<SubscriberEmail>/g, pSubscriber).replace(/<SubscriberEmailId>/g, vId)

        let datetimeStr = "1999-01-01 01:00:00";
        let isoDate = convertToISO(datetimeStr);

        let vNewsletterEditionCreateItem = {
          "id": vId,
          "AccountGroup": vNewsletterEditionSelected["AccountGroup"],
          "CampaignName": vNewsletterEditionSelected["CampaignName"],
          "Newsletter": vNewsletterEditionSelected["Newsletter"],
          "Edition": vNewsletterEditionSelected["Edition"],
          "EmailHTML": vContentHTMLFinal,
          "SESMessageId": "",
          "S3HtmlLocation": "",
          "S3Bucket": "rhyteitnewsletters",
          "S3Key": vS3Key,
          "RecipientEmail": pSubscriber,
          "SendDateTime": isoDate,
          "FirstOpenDateTime": isoDate,
          "OpenDateTime": isoDate,
          "LastClickDateTime": isoDate,
          "UnsubscribeDateTime": isoDate,
          "SendCount": 0,
          "OpenCount": 0,
          "ClickCount": 0,
          "DeliveryStatus": pDeliveryStatus,
          "BounceReason": "",
          "UnsubscribeStatus": false,
          "AudienceSegment": "Internal",
          "DeviceType": "",
          "UserAgent": "",
          "IPAddress": "",
          "City": "",
          "Country": "",
          "ContentVersion": 1,
          "NewsletterTrackingStatus": pDeliveryStatus,
          "CreationName": Users,
          "UpdateName": Users,
          "CreationDateTime": vUpdateDateTime,
          "UpdateDateTime": vUpdateDateTime
        }

         //console.log("NewsletterSubscriberTable.CreateNewsletterEditionTracking.vNewsletterEditionCreateItem: ",vNewsletterEditionCreateItem)

          let createNewsletterEditionTrackingResponse = await client.graphql({                    
                  query: createNewsletterEditionTracking,
                  variables:{input: vNewsletterEditionCreateItem}            
            }
          )
        
        //console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.createNewsletterEditionTrackingResponse: ",createNewsletterEditionTrackingResponse)

        setvAddSubscribersVisible(false);
        setvNewSubscriber("");
        GetNewsletterTrackingTable()

      } catch (err) {
        console.error("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.Error:  ".err, err.stack); 
        RhyteItLogging(
          Users,
          "NewsletterSubscriberTable",
          "CreateSubscriber",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }

    async function ReplaceTemplateVariables() {
        
  
      try {           
       
        let vContentHTMLTmp = vContentHTML


        vContentHTMLTmp = vContentHTMLTmp.replace(/<NewsletterTitle>/g, vNewsletter["Newsletter"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PixelTrackerAPIURL>/g, vTemplateJSON["PixelTrackerAPIURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<NewsletterBannerURL>/g, vTemplateJSON["NewsletterBannerURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionTitle>/g,vContentJSON["Sections"][5]["Introduction"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionText>/g,vContentJSON["Sections"][5]["Introduction"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionButtonURL>/g,vContentJSON["Sections"][5]["Introduction"]["ButtonURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<IntroductionButtonTitle>/g,vContentJSON["Sections"][5]["Introduction"]["ButtonTitle"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<InThisIssueText>/g,vContentJSON["Sections"][0]["In This Issue"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastTitle>/g,vContentJSON["Sections"][4]["Podcast"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastAVURL>/g,vContentJSON["Sections"][4]["Podcast"]["AVURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastImageURL>/g,vContentJSON["Sections"][4]["Podcast"]["ImageURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastButtonURL>/g,vContentJSON["Sections"][4]["Podcast"]["ButtonURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<PodcastButtonTitle>/g,vContentJSON["Sections"][4]["Podcast"]["ButtonTitle"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<FeaturedArticleTitle>/g,vContentJSON["Sections"][1]["Featured Article"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<FeaturedArticleText>/g,vContentJSON["Sections"][1]["Featured Article"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<QuickTipsTitle>/g,vContentJSON["Sections"][2]["Quick Tips"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<QuickTipsText>/g,vContentJSON["Sections"][2]["Quick Tips"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryTitle>/g,vContentJSON["Sections"][3]["Success Story"]["Title"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryText>/g,vContentJSON["Sections"][3]["Success Story"]["Text"].replace(/\n/g, "<br/>"))
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryButtonURL>/g,vContentJSON["Sections"][3]["Success Story"]["ButtonURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<SuccessStoryButtonTitle>/g,vContentJSON["Sections"][3]["Success Story"]["ButtonTitle"])

        
        vContentHTMLTmp = vContentHTMLTmp.replace(/<UnsubscriberURL>/g, vTemplateJSON["UnsubscriberURL"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<NewsletterEmail>/g, vTemplateJSON["NewsletterEmail"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<Copyright>/g, vTemplateJSON["Copyright"])
        vContentHTMLTmp = vContentHTMLTmp.replace(/<Address>/g, vTemplateJSON["Address"])
        
        
       

        setvContentHTML(vContentHTMLTmp);

        } catch (err) { 
          RhyteItLogging(Users, "NewsletterSubscriberTable", "ReplaceTemplateVariables",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          console.error("NewsletterSubscriberTable.ReplaceTemplateVariables.Error:  ".err, err.stack); 
      }        
    }  

    async function DeleteAllRows(event) {
      try {

        
        vSubscribers.forEach(vNewsletterSubscriber => {
        
          DeleteRow(vNewsletterSubscriber) 

          });

          GetNewsletterTrackingTable()
      
          } catch (err) { 

            RhyteItLogging(Users, "NewsletterSubscriberTable", "DeleteAllRows",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterSubscriberTable.DeleteAllRows.Error:  ".err, err.stack);       
        }               
      
    }      

    async function DeleteRow(pSubsciber) {
      try {

              
              setErrorMessage("")   

              const DeleteNewsletterEditionTrackingRow = {
                id: pSubsciber.id,
                _version: pSubsciber._version
              }
              

              const vDeleteResponse = await client.graphql({
                query: deleteNewsletterEditionTracking,
                variables: {
                  input: DeleteNewsletterEditionTrackingRow
                }
              });  

              ErrorMessageStr = vDeleteResponse
              setErrorMessage(ErrorMessageStr)  

              
      
          } catch (err) { 

            RhyteItLogging(Users, "NewsletterSubscriberTable", "DeleteRow",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterSubscriberTable.DeleteRow.Error:  ".err, err.stack);       
        }               
      
    }       



  return (   
    <section ref={ref} className = {styles.Section}>
      {
        vNewsletter !== undefined  && (                      
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>   
            

        <motion.div 
              className = {styles.NewsletterTitleDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.NewsletterTitle}>
              {vNewsletter["Newsletter"]}
            </motion.h1>   
        </motion.div>             
        <motion.div 
              className = {styles.NewsletterDescriptionDiv}
              style={{
                  transform: isInView ? "none" : "translateX(-200px)",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                }}> 
            <motion.h1  className = {styles.NewsletterDescription}>
              {vNewsletter["Description"]}
            </motion.h1>   
        </motion.div>  
        <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="nowrap"
               gap="1rem"
               className={styles.FlexRow}             >      
        <SelectField
                className={styles.SelectField}
                value={vNewsletterEditionSelected.Edition}
                placeholder="Select newsletter edition"
                onChange={(e) => NewsletterEditionSelectedAction(e.target.value)}
              >
                {vNewsletterEditionList !== undefined &&
                  vNewsletterEditionList.map((NewsletterEdition) => (
                    <option
                      key={NewsletterEdition.Edition}
                      className={styles.SelectFieldOption}
                      value={NewsletterEdition.Edition}
                      label={NewsletterEdition.Edition}
                    >
                      {NewsletterEdition.Edition}
                    </option>
                  ))}
        </SelectField>    
        <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => PublishNewsletter(e)}
              >
                Publish
            </Button>        
        <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => SendNewsletter(e)}
              >
                Send
            </Button>   
        <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => GetNewsletterTrackingTable(e)}
              >
                Refresh
            </Button>     
        <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => DeleteAllRows(e)}
              >
                Delete All
            </Button>     
                     
        </Flex>           
        <Table className={styles.Table}   highlightOnHover={true}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Expand</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Actions</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Email</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Status</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Sent</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >First Open</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Update</TableCell>
            </TableRow>                                      
          </TableHead>       
          
              {
                vSubscribers !== undefined && vNewsletter !== undefined && vSubscribers.map((Subscriber) => (                      
                  (<NewsletterPublisherRow   key={Subscriber.UpdateDateTime}  Users={Users} Newsletter={vNewsletter} Subscriber={Subscriber}/>)        
                  ))
              }                    
        </Table>   
        </Flex>                 
      )}      
    </section>
  )
}

export default NewsletterPublisherTable;


