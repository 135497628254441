import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  getApplicants,
} from "../../graphql/queries";

import {
  updateApplicants,
} from "../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import Header from "../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";
import MenuBackground from "./../Menu/MenuBackground";
import { useLocation } from 'react-router-dom';

import {
  Button,
  Flex,
} from "@aws-amplify/ui-react";
import styles from "./Unsubscribe.module.css";

const Unsubscribe = (props) => {
  Unsubscribe.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();
  const [vUnsubscribe, setvUnsubscribe] = useState([]);

  const [status, setStatus] = useState('Processing your request...');
  const query = new URLSearchParams(useLocation().search);
  const vEmail = query.get('Email');

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);
  const [vApplicant, setvApplicant] = useState();

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    console.log("Unsubscribe.useEffect[vEmail].vEmail: ",vEmail)

    if (vEmail) {
      
      GetSubscriber(vEmail)

    }

  }, [vEmail]);

  useEffect(() => {

    console.log("Unsubscribe.useEffect[vApplicant].vApplicant: ",vApplicant)
    
    if (vApplicant) {
      
      UnsubscribeUser(vApplicant)

    }

  }, [vApplicant]);


  async function GetSubscriber(pNewUser) {
    try {

      
      console.log("Unsubscribe.GetSubscriber.vEmail: ",vEmail)

      const getApplicantsResponse = await client.graphql({
        query: getApplicants,
        variables: {
          "id": vEmail,
          "UserId": vEmail
        }
      });  
      
      console.log("Unsubscribe.GetSubscriber.getApplicantsResponse: ",getApplicantsResponse["data"]["getApplicants"])

      if (getApplicantsResponse["data"]["getApplicants"]) {

        
      //console.log("Unsubscribe.INSIDE.getApplicantsResponse[data][getApplicants]")


        var vUpdateDateTime = new Date().toISOString()

        let vApplicantItem = {
          "id":getApplicantsResponse["data"]["getApplicants"]["id"],
          "UserId": getApplicantsResponse["data"]["getApplicants"]["UserId"],
          "UserEmail": getApplicantsResponse["data"]["getApplicants"]["UserEmail"],
          "Type":getApplicantsResponse["data"]["getApplicants"]["Type"],
          "UserLastName":getApplicantsResponse["data"]["getApplicants"]["UserLastName"],
          "UserFirstName":getApplicantsResponse["data"]["getApplicants"]["UserFirstName"],
          "UserDialCode":getApplicantsResponse["data"]["getApplicants"]["UserDialCode"],
          "UserPhone":getApplicantsResponse["data"]["getApplicants"]["UserPhone"],
          "SocialMediaProfile":getApplicantsResponse["data"]["getApplicants"]["SocialMediaProfile"],
          "ipAddress": getApplicantsResponse["data"]["getApplicants"]["ipAddress"],
          "PrivacyAcknowledgement": getApplicantsResponse["data"]["getApplicants"]["PrivacyAcknowledgement"],
          "Application": getApplicantsResponse["data"]["getApplicants"]["Application"],
          "EmailTemplate": getApplicantsResponse["data"]["getApplicants"]["EmailTemplate"],
          "Role": getApplicantsResponse["data"]["getApplicants"]["Role"],
          "Status": getApplicantsResponse["data"]["getApplicants"]["Status"],
          "Active": getApplicantsResponse["data"]["getApplicants"]["Active"],
          "NewsLetterStatus": "Unsubscribed",
          "NewsLetterConsent": true,
          "InOnlyFansProspectingProfile": getApplicantsResponse["data"]["getApplicants"]["InOnlyFansProspectingProfile"],
          "CreationName":getApplicantsResponse["data"]["getApplicants"]["CreationName"],
          "UpdateName":"Unsubscribed",
          "CreationDateTime":getApplicantsResponse["data"]["getApplicants"]["CreationDateTime"],
          "UpdateDateTime":vUpdateDateTime
        }             
        
        setvApplicant(vApplicantItem)


      }

      } catch (err) {   
              ErrorMessageStr = 'Unsubscribe.GetSubscriber.ERROR:  ' + err 
              console.error(ErrorMessageStr); 
              setErrorMessage(ErrorMessageStr)
      }        
}     


  async function UnsubscribeUser(pNewUser) {
    try {

        let ReturnMessage = ""
    

                    try {

                        const ReturnMessage = await client.graphql({
                            query: updateApplicants,
                            variables: {
                              input: vApplicant
                            }
                          });             
                        
                          console.log("Unsubscribe.UnsubscribeUser.ReturnMessage: ",ReturnMessage)
                        
                          ErrorMessageStr = "We are sorry to see you go " + vEmail +  "." 
                          setErrorMessage(ErrorMessageStr)
                     
                    } catch (err) { 

                        RhyteItLogging(vEmail, "Unsubscribe", "UnsubscribeUser",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                }     





    } catch (err) {   
            ErrorMessageStr = 'Unsubscribe.UnsubscribeUser.ERROR:  ' + err 
            console.error(ErrorMessageStr); 
            setErrorMessage(ErrorMessageStr)
    }        
}     

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        
        <div className={styles.UnsubscribedBox}>
          <h1 className={styles.Text}>You have unsubscribed to our newsletter</h1>
        </div>
        <div className={styles.WhiteBox}>
          <h1 className={styles.Text}>{ErrorMessage}</h1>
          <h1 className={styles.Text}>Hope you come back :)</h1>
        </div>

      </Flex>
    </m.div>
  );
};

export default Unsubscribe;
