import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import  { listRhyteItEmails }  from '../../graphql/queries'
import  { }  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import Header from "../RhyteItComponents/Header/Header";
import MenuBackground from '../Menu/MenuBackground';
import {motion as m} from "framer-motion";
import EmailTable from "./EmailTable";

import {Link, useNavigate } from 'react-router-dom';

import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';

import {
  Button , Flex,
  useAuthenticator
} from '@aws-amplify/ui-react';
import styles from './EmailManagement.module.css';

const EmailManagement = (props) => {       
  
  EmailManagement.propTypes = {
          RedditUser: PropTypes.object
        };

        const client = generateClient();
        const [RhyteItEmails, setRhyteItEmails] = useState([]) 
        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/EmailManagement", Title:"Emails", isCurrent:true}]
     
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        let debugConsoleOutPut = true
        
        const { authStatus } = useAuthenticator(context => [context.authStatus]);

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);       
        const [vUserRole, setvUserRole] = useState();
        const [EmailsReturned, setEmailsReturned] = useState(0);
        const [ShowTable, setShowTable] = useState(false);
        const [Email, setEmail] = useState();
        

        const [updatedKey, setupdatedKey] = useState(Date.now())  
        const [NewEmailKey, setNewEmailKey] = useState(Date.now())   
        let navigate = useNavigate(); 

        
        const [nextToken, setnextToken] = useState(undefined) 
        const [IsLoading, setIsLoading] = useState(false)

        useEffect(() => {

        

        },[]) 

        useEffect(() => {
             
          (async function() {
            try {  

                    if (CognitoGroup !== undefined) {

                      let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")
                      
                      if (GetGroupRoleAccessResponse) {  
                        //console.log("EmailManagement.useEffect[CognitoGroup].GetGroupRoleAccessResponse:  ",GetGroupRoleAccessResponse)
                        GetEmailManagement()  
                      }
                          

                    } else {

                      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

                      if (idToken !== undefined) {
                        setUsers(idToken.payload.email)
                        setCognitoGroup(accessToken["payload"]["cognito:groups"])
                      } else {
                        
                        ReturnUserToLandingPage(Users, CognitoGroup)
                      }

                    }
                  } catch (err) {                  
                    RhyteItLogging(Users, "EmailManagement", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                }
              }
            )();   
    
           },[CognitoGroup])           

        useEffect(() => {

          
          //console.log("EmailManagement.useEffect[Users].Users:  ",Users);

 
         },[Users])             

        useEffect(() => {
          //console.log("EmailManagement.useEffect[RhyteItEmails].RhyteItEmails:  ",RhyteItEmails)
          //console.log("SBSMQueries.useEffect[SBSMNewsQueryTerms].Object.keys(SBSMNewsQueryTerms).length:  ",Object.keys(SBSMNewsQueryTerms).length )
 
           if (Object.keys(RhyteItEmails).length > 0) {
             setShowTable(true)
           }
 
           setErrorMessage("Emails retrieved at: " + new Date().toLocaleString())
 
         },[RhyteItEmails])     
   
         useEffect(() => {

          if (Object.keys(RhyteItEmails).length > 0 && IsLoading === false) {

            setEmailsReturned(RhyteItEmails.length)
            SortEmailsByDate()
          }
 
         },[IsLoading])     

        useEffect(() => {

          const fetch1 = async () => {
           

            try {

                  let RhyteItEmailsData = null

                  if(nextToken !== null && nextToken !== undefined) {

                    
                    //console.log("EmailManagement.fetch1.nextToken.length: ",nextToken.length);

                    setIsLoading(true)

                      var datetime = new Date();                                                
                      let limit = 10000000
                      //console.log("TwitterStats.useEffect[NextToken].nextToken: ", nextToken);   
                      
                      let  variables = {
                        nextToken,
                        filter: {
                        }
                      };   
                      
          
                      const EmailManagementData2  = await client.graphql({
                        query: listRhyteItEmails,
                        variables: variables
                      });

                    let vToken = EmailManagementData2.data.listRhyteItEmails.nextToken

                   // //console.log("EmailManagement.fetch1.vToken:  ",vToken)
                  //  //console.log("EmailManagement.fetch1.EmailManagementData2.data.listRhyteItEmails.items.length: ",EmailManagementData2.data.listRhyteItEmails.items.length);
                    
                    //console.log("EmailManagement.fetch1.EmailManagementData2.data.listRhyteItEmails.nextToken:  ",EmailManagementData2.data.listRhyteItEmails.nextToken)
                  
                    if (EmailManagementData2.data.listRhyteItEmails.items.length > 0) {

                        RhyteItEmailsData = RhyteItEmails          
              
                        EmailManagementData2.data.listRhyteItEmails.items.forEach(item => 
                          {   
                        
                            if ([...RhyteItEmailsData].filter(Content => item.id === Content.id)["length"] === 0) {  
                              RhyteItEmailsData.push(item) 
                            }
      
                          })

                            setRhyteItEmails(RhyteItEmailsData)  

                            if (vToken !== null ) {
                              setnextToken(vToken)  

                            } else if (vToken === null){          
                        
                            //  //console.log("EmailManagement.fetch1.EmailManagementData2.data.listRhyteItEmails.nextToken.null")
                              setIsLoading(false)
                              setEmailsReturned(RhyteItEmailsData.length)
                              SortEmailsByDate()
                                                                    
              
                            }
                    } else if (vToken === null){          
                      
                  //    //console.log("EmailManagement.fetch1.EmailManagementData2.data.listRhyteItEmails.nextToken.null2")                      
                      
                      setIsLoading(false)
                      setEmailsReturned(RhyteItEmailsData.length)
                      SortEmailsByDate()
                                                            
      
                    }
                  }
      
            } catch (err) {
              
                console.error("EmailManagement.useEffect.[nextToken].fetch1.Error: ", err, err.stack)
            } finally {
              setIsLoading(false)           
            }
          }
        
          fetch1()

        }, [nextToken])            
  
        async function SortEmailsByDate() {
          try {


            if (IsLoading === false && RhyteItEmails.length > 0 && ShowTable === false) {

              setEmailsReturned(RhyteItEmails.length)
              const EmailManagement1 = RhyteItEmails
              let EmailManagementSorted = await SortTEmailsByDate(EmailManagement1)  

              setRhyteItEmails(EmailManagementSorted)                  

                        
              setupdatedKey(Date.now())            
              setShowTable(true)
            }

          } catch (err) { 
            RhyteItLogging(Users, "EmailManagement", "GetEmailManagement",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }   

        async function GetEmailManagement() {
          try {
            //console.log("EmailManagement.GetEmailManagement.Start");

                    setErrorMessage("")
                    setRhyteItEmails([])

                    const EmailManagementData2  = await client.graphql({
                      query: listRhyteItEmails
                    });
      

                    
                    if (EmailManagementData2.data.listRhyteItEmails.items.length > 0) {
                        //console.log("EmailManagementData2.data.listEmailManagement.items.length: ",EmailManagementData2.data.listEmails.items.length);
                         
                        setRhyteItEmails(EmailManagementData2.data.listRhyteItEmails.items)    
                       
                        
                      
                        if (EmailManagementData2.data.listRhyteItEmails.nextToken !== null) {

                          //console.log("EmailManagement.GetEmailManagement.EmailManagementData2.data.listRhyteItEmails.nextToken.length: ",EmailManagementData2.data.listRhyteItEmails.nextToken.length);
                          
                          setnextToken(EmailManagementData2.data.listRhyteItEmails.nextToken)  
              
                        }  else {

                          //console.log("EmailManagement.GetEmailManagement.setIsLoading(false)");
                          
                          setIsLoading(false)
                          SortEmailsByDate()

                        }

                        }  
           
                

          } catch (err) { 
            ErrorMessageStr = ("EmailManagement.GetEmailManagement.Error: ", err, err.stack)
            console.error(ErrorMessageStr)
            RhyteItLogging(Users, "EmailManagement", "GetEmailManagement",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                      }
        }         

        function SortTEmailsByDate(pEmails) {
              pEmails.sort(GFG_sortFunction);
              return pEmails
          //    //console.log(JSON.stringify(pEmails));
          }

        function GFG_sortFunction(a, b) {
            let dateA = new Date(a.CreationDateTime).getTime();
            let dateB = new Date(b.CreationDateTime).getTime();
            return dateA < dateB ? 1 : -1;
        };  

        function NewEmailRow(event, data) {
          try {           
            
              setErrorMessage("")


            } catch (err) { 
              ErrorMessageStr = ("Email.EditEmailRow.Error: ", err, err.stack)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }      
        

    return ( 
    <m.div
      key="Search"
      initial={{x: "100%"}}
      animate={{x: "0%"}}
      transition={{duration:0.75, ease: "easeOut"}}
      exit={{ opacity: 1 }}
     >
                  <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#cc6633"   GradientStopColor4="#8880"/>                          
                  <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="3rem"
                      className={styles.GridContainer}
                    >     
                      <Header Title="Email Management" SubTitle="Manage Emails" BreadCrumbItems ={vBreadCrumbItems} pageSource="EmailManagement"/>  
                      <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          alignContent="flex-start"
                          wrap="nowrap"
                          gap="3rem"
                        >             
                        <Link key={NewEmailKey} isExternal={false} to="/EmailCompose" >                      
                              <Button
                                loadingText=""
                                ariaLabel=""
                                className={styles.RedditTitleButtons}
                                onClick={(e) => NewEmailRow(e, Email)}
                              > Compose 
                              </Button>  
                        </Link>                                                                                                                                                                                        
                      </Flex>                                
                      <div className={styles.ResultsInfo}>{ErrorMessage} Emails retrieved: {EmailsReturned}</div>  
                      {ShowTable && RhyteItEmails.length > 0 && (<EmailTable   key={updatedKey}  RhyteItEmails={RhyteItEmails}/>)  }                                                                                                              
                    </Flex>    
    </m.div>                      

    )

  }
 

export default EmailManagement;